import React, { memo } from 'react';
import AudioPlayer from './AudioPlayer';

const AudioFilesList = memo(({ audioFiles, onDownload }) => {
  return (
    <div className="divide-y divide-gray-100">
      {audioFiles.map((audio) => (
        <div 
          key={audio.id} 
          className="py-4 first:pt-0 last:pb-0 hover:bg-gray-50/50 transition-colors"
        >
          <AudioPlayer 
            audioId={audio.id}
            filename={audio.filename}
          />
        </div>
      ))}
    </div>
  );
});

AudioFilesList.displayName = 'AudioFilesList';

export default AudioFilesList; 