import React from 'react';
import axios from 'axios';
import { FaTrash } from 'react-icons/fa';

const ManageVoicesModal = ({ userVoices = [], onVoiceRemoved, onClose }) => {
  const handleRemoveVoice = async (voiceId) => {
    try {
      await axios.delete(`/voices/custom/${voiceId}`, {
        withCredentials: true,
      });
      onVoiceRemoved(voiceId);
    } catch (error) {
      console.error('Error removing voice:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm overflow-y-auto h-full w-full z-[60]">
      <div className="relative top-20 mx-auto p-5 w-full max-w-md">
        <div className="relative bg-white rounded-2xl shadow-xl p-8">
          {/* Header */}
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-xl font-bold text-gray-900">Manage Custom Voices</h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 transition-colors"
              aria-label="Close modal"
            >
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          {/* Voice List */}
          <div className="space-y-3 max-h-[400px] overflow-y-auto">
            {userVoices.length === 0 ? (
              <p className="text-center text-gray-500 py-4">No custom voices added yet.</p>
            ) : (
              userVoices.map(voice => (
                <div 
                  key={voice.id}
                  className="flex items-center justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-200"
                >
                  <div className="flex flex-col">
                    <span className="font-medium text-gray-900">{voice.name}</span>
                    <span className="text-sm text-gray-500">{voice.id}</span>
                  </div>
                  <button
                    onClick={() => handleRemoveVoice(voice.id)}
                    className="p-2 text-red-500 hover:text-red-600 hover:bg-red-50 rounded-lg transition-all duration-200"
                    aria-label="Remove voice"
                  >
                    <FaTrash className="w-4 h-4" />
                  </button>
                </div>
              ))
            )}
          </div>

          {/* Footer */}
          <div className="mt-6 flex justify-end">
            <button
              onClick={onClose}
              className="px-6 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-all duration-200"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageVoicesModal; 