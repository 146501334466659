import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import PDFDisplay from './PDF_display/pdf-display';
import { Auth } from './components/Auth';
import { API_BASE_URL } from './config';
import FileDropZone from './components/FileDropZone';
import AudioFilesList from './components/AudioFilesList';
import ManageVoicesModal from './components/ManageVoicesModal';
import AddVoiceModal from './components/AddVoiceModal';

// Configure axios defaults
axios.defaults.baseURL = API_BASE_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function App() {
  // State declarations
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState('lily');
  const [user, setUser] = useState(null);
  const [isVoicesLoading, setIsVoicesLoading] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const [showAddVoice, setShowAddVoice] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showManageVoices, setShowManageVoices] = useState(false);
  const [fileState, setFileState] = useState({
    selectedFile: null,
    pdfPath: null,
    isDragging: false,
    isLoading: false,
    errorMessage: null
  });

  const [audioState, setAudioState] = useState({
    audioFiles: [],
  });

  const fetchVoicesRef = useRef(null);
  const handleLogoutRef = useRef(null);
  const filteredCustomVoices = voices.filter(voice => voice.type === "custom");

  // State to manage the dropdown visibility
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Function to toggle dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  // Close dropdown when clicking outside
  const dropdownRef = useRef(null);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Helper functions to update state
  const updateFileState = useCallback((updates) => {
    setFileState(prev => ({
      ...prev,
      ...updates
    }));
  }, []);

  const updateAudioState = useCallback((updates) => {
    setAudioState(prev => ({
      ...prev,
      ...updates
    }));
  }, []);

  // Core functions
  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('userEmail');
    delete axios.defaults.headers.common['Authorization'];
    setUser(null);
    setVoices([]);
    if (fetchVoicesRef.current) {
      fetchVoicesRef.current();
    }
  }, []);

  const fetchVoices = useCallback(async () => {
    setIsVoicesLoading(true);
    try {
      // Always fetch public voices first
      const publicResponse = await axios.get('/voices/public');
      let allVoices = publicResponse.data.voices || [];

      // Then try to fetch user's custom voices if authenticated
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const customResponse = await axios.get('/voices/all', {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          
          if (customResponse.data.voices) {
            // Combine public and custom voices, removing duplicates
            const customVoices = customResponse.data.voices.filter(v => v.type === 'custom');
            allVoices = [...allVoices, ...customVoices];
          }
        } catch (error) {
          if (error.response?.status === 422 || error.response?.status === 401) {
            if (handleLogoutRef.current) {
              handleLogoutRef.current();
              setShowAuth(true);
            }
          }
          // If custom voices fetch fails, we still have public voices
        }
      }
      
      setVoices(allVoices);
    } catch (error) {
      console.error('Error fetching voices:', error);
      setVoices([]); // Set empty array if everything fails
    } finally {
      setIsVoicesLoading(false);
    }
  }, []);

  // Store fetchVoices and handleLogout in refs
  useEffect(() => {
    fetchVoicesRef.current = fetchVoices;
    handleLogoutRef.current = handleLogout;
  }, [fetchVoices, handleLogout]);

  // Authentication related functions
  const handleAuthSuccess = (userData) => {
    const { token, email } = userData;
    if (!token) {
      console.error('No token received from authentication');
      return;
    }
    
    localStorage.setItem('token', token);
    localStorage.setItem('userEmail', email);
    setUser({ email });
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    setShowAuth(false);
    setTimeout(() => {
      fetchVoices();
    }, 0);
  };

  const initializeAuth = useCallback(() => {
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('userEmail');
    
    if (token && email) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setUser({ email });
      return true;
    }
    return false;
  }, []);

  // Voice management functions
  const handleVoiceAdded = (newVoice) => {
    const formattedVoice = {
      id: newVoice.id,
      name: newVoice.name,
      type: "custom",
      is_public: false
    };
    setVoices(prevVoices => [...prevVoices, formattedVoice]);
  };

  const handleVoiceRemoved = (voiceId) => {
    setVoices(prevVoices => prevVoices.filter(voice => voice.id !== voiceId));
  };

  // File handling functions
  const handleFileSelect = useCallback(async (file) => {
    updateFileState({
      selectedFile: file,
      errorMessage: null
    });

    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        updateFileState({
          pdfPath: e.target.result
        });
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Error reading file:', error);
      updateFileState({
        errorMessage: 'Failed to read the selected file.'
      });
    }
  }, [updateFileState]);

  const handleDownload = useCallback(async (audioFile) => {
    setIsLoading(true); // Start loading
    try {
      const response = await axios.get(`/audio/${audioFile.id}`, {
        responseType: 'blob'
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${audioFile.name}.mp3`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading audio:', error);
      updateFileState({
        errorMessage: 'Failed to download audio file.'
      });
    } finally {
      setIsLoading(false); // End loading
    }
  }, [updateFileState]);

  const handleClearPdf = useCallback(() => {
    updateFileState({
      selectedFile: null,
      pdfPath: null,
      isDragging: false,
      errorMessage: null
    });
    updateAudioState({
      audioFiles: []
    });
  }, [updateFileState, updateAudioState]);

  const handleUpload = async () => {
    if (!fileState.selectedFile) {
      updateFileState({ errorMessage: 'Please select a PDF file first.' });
      return;
    }

    setIsLoading(true);
    updateFileState({ errorMessage: null });

    const formData = new FormData();
    formData.append('pdf_file', fileState.selectedFile);
    formData.append('voice', selectedVoice);

    try {
      const response = await axios.post(`/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });

      updateAudioState({
        audioFiles: [...audioState.audioFiles, ...response.data.audio_files]
      });
    } catch (error) {
      console.error('Upload error:', error);
      let errorMsg = 'Failed to upload file. Please try again.';
      if (error.response && error.response.data && error.response.data.error) {
        errorMsg = error.response.data.error;
      }
      updateFileState({
        errorMessage: errorMsg
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Handle auth-error event
  useEffect(() => {
    const handleAuthError = () => {
      setShowAuth(true);
    };

    window.addEventListener('auth-error', handleAuthError);
    return () => window.removeEventListener('auth-error', handleAuthError);
  }, []); // Empty dependency array since handleLogout is stable via useRef

  // Initialize authentication on mount
  useEffect(() => {
    const isAuthenticated = initializeAuth();
    if (isAuthenticated) {
      fetchVoices();
    } else {
      fetchVoices(); // Fetch public voices even if not authenticated
    }
  }, [initializeAuth, fetchVoices]);

  // Rendering functions
  const renderHeader = () => {
    return (
      <header className="mb-8">
        <div className="flex justify-between items-center flex-row sm:gap-0 gap-4 relative">
          <h1 className="text-3xl sm:text-4xl font-extrabold bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 bg-clip-text text-transparent text-center sm:text-left animate-gradient bg-200%">
            Easy Document Reader
          </h1>
          {/* Hamburger Menu for Small Screens */}
          <div className="sm:hidden flex items-center relative" ref={dropdownRef}>
            <button
              onClick={toggleDropdown}
              className="text-gray-800 hover:text-gray-600 focus:outline-none"
              aria-label="Toggle Menu"
              aria-expanded={isDropdownOpen}
            >
              {/* Hamburger Icon */}
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                {isDropdownOpen ? (
                  // Close Icon
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  // Hamburger Icon
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
            {/* Dropdown Menu */}
            {isDropdownOpen && (
              <div className="absolute top-12 right-0 w-48 bg-white border rounded-lg shadow-lg py-2 z-50">
                {user ? (
                  <>
                    <button
                      onClick={() => {
                        setShowAddVoice(true);
                        setIsDropdownOpen(false);
                      }}
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Add Voice
                    </button>
                    <button
                      onClick={() => {
                        setShowManageVoices(true);
                        setIsDropdownOpen(false);
                      }}
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Manage Voices
                    </button>
                    <button
                      onClick={() => {
                        handleLogout();
                        setIsDropdownOpen(false);
                      }}
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Logout
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => {
                      setShowAuth(true);
                      setIsDropdownOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    Sign In
                  </button>
                )}
              </div>
            )}
          </div>
          {/* Standard Menu for Larger Screens */}
          <div className="hidden sm:flex items-center space-x-4">
            {user ? (
              <div className="flex space-x-4">
                <button
                  onClick={() => setShowAddVoice(true)}
                  className="px-4 py-2 bg-teal-600 text-white rounded-lg shadow hover:bg-teal-700 transition"
                >
                  Add Voice
                </button>
                <button
                  onClick={() => setShowManageVoices(true)}
                  className="px-4 py-2 bg-purple-600 text-white rounded-lg shadow hover:bg-purple-700 transition"
                >
                  Manage Voices
                </button>
                <button
                  onClick={handleLogout}
                  className="px-4 py-2 bg-red-500 text-white rounded-lg shadow hover:bg-red-600 transition"
                >
                  Logout
                </button>
              </div>
            ) : (
              <button
                onClick={() => setShowAuth(true)}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 transition"
              >
                Sign In
              </button>
            )}
          </div>
        </div>
      </header>
    );
  };

  const renderVoiceSelection = () => {
    if (isVoicesLoading) {
      return (
        <div className="mb-8">
          <div className="animate-pulse h-12 bg-gray-300 rounded-lg"></div>
        </div>
      );
    }

    return (
      <div className="mb-8">
        <label htmlFor="voice-select" className="block text-lg font-semibold text-gray-700 mb-3">
          Choose Your Voice
        </label>
        <select
          id="voice-select"
          value={selectedVoice}
          onChange={(e) => setSelectedVoice(e.target.value)}
          className="block w-full px-4 py-3 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        >
          {voices.map((voice) => (
            <option key={voice.id} value={voice.id}>
              {voice.name} {voice.type === 'custom' ? '(Custom)' : ''}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const FileUploadSection = () => {
    return (
      <div className="mt-6">
        <FileDropZone
          onFileSelect={handleFileSelect}
          isDragging={fileState.isDragging}
          setIsDragging={(isDragging) => updateFileState({ isDragging })}
        />
        <div className="mb-4 text-sm text-gray-600">
          {user ? (
            'You can upload multi-page PDFs.'
          ) : (
            'Note: Non-authenticated users are limited to uploading one-page PDFs.'
          )}
        </div>
      </div>
    );
  };

  const AudioFilesSection = () => {
    return (
      <div className="mb-8">
        <AudioFilesList
          audioFiles={audioState.audioFiles}
          onDownload={handleDownload}
          isLoading={isLoading}
        />
        <div className="mt-6">
          <button
            onClick={handleUpload}
            disabled={isLoading || !fileState.selectedFile}
            className={`w-full px-5 py-3 text-white rounded-lg shadow transition ${
              isLoading || !fileState.selectedFile
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-indigo-600 hover:bg-indigo-700'
            }`}
          >
            {isLoading ? 'Processing...' : 'Convert to Speech'}
          </button>
        </div>
        {fileState.errorMessage && (
          <div className="mt-4 p-4 bg-red-100 text-red-700 rounded-lg">
            {fileState.errorMessage}
          </div>
        )}
      </div>
    );
  };

  const PDFPreviewSection = () => {
    return (
      <div className="relative">
        <button
          onClick={handleClearPdf}
          className="absolute top-3 right-3 p-3 bg-white rounded-full shadow hover:bg-gray-100 transition z-10"
        >
          <svg className="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <PDFDisplay pdfPath={fileState.pdfPath} />
      </div>
    );
  };


  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-100 to-gray-200">
      <div className="max-w-6xl mx-auto px-6 py-10">
        {renderHeader()}

        {/* Auth Modal */}
        {showAuth && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-xl shadow-lg w-full max-w-md p-6 relative">
              <button
                onClick={() => setShowAuth(false)}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
              >
                <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              <Auth onAuthSuccess={handleAuthSuccess} />
            </div>
          </div>
        )}

        {/* Main content */}
        <div className="bg-white rounded-3xl shadow-lg p-8">
          {renderVoiceSelection()}

          {!fileState.pdfPath ? (
            <FileUploadSection />
          ) : (
            <div className="grid grid-cols-1 gap-16">
              <AudioFilesSection />
              <PDFPreviewSection />
            </div>
          )}
        </div>
      </div>
      <footer className="text-center text-gray-500 text-sm mt-10">
        <a href="https://lmnt.com" target="_blank" rel="noopener noreferrer" className="underline">
          Powered by LMNT text-to-speech
        </a>
      </footer>

      {/* Modals */}
      {showManageVoices && (
        <ManageVoicesModal
          userVoices={filteredCustomVoices}
          onVoiceRemoved={handleVoiceRemoved}
          onClose={() => setShowManageVoices(false)}
        />
      )}

      {/* Add Voice Modal */}
      {showAddVoice && (
        <AddVoiceModal
          onVoiceAdded={handleVoiceAdded}
          onClose={() => setShowAddVoice(false)}
        />
      )}
    </div>
  );
}

export default App;
