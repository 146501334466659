import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// Initialize PDF.js worker
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

function PDFDisplay({ pdfPath }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    console.log(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function zoomIn() {
    setScale(prevScale => Math.min(prevScale + 0.2, 3.0));
  }

  function zoomOut() {
    setScale(prevScale => Math.max(prevScale - 0.2, 0.5));
  }

  return (
    <div className="flex flex-col items-center w-full max-w-full my-5">
      <Document
        file={pdfPath}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(error) => {
          console.error('Error loading PDF:', error);
        }}
        className="flex flex-col items-center"
      >
        <div className="shadow-lg rounded-lg overflow-hidden">
          <Page 
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            scale={scale}
            className="max-w-full h-auto"
          />
        </div>
      </Document>

      {/* Controls */}
      <div className="flex items-center gap-4 mt-4">
        {/* Zoom controls */}
        <button
          type="button"
          onClick={zoomOut}
          className="px-4 py-2 rounded-md text-sm font-medium bg-gray-100 text-gray-700 hover:bg-gray-200 transition-colors duration-200"
        >
          Zoom Out
        </button>

        <span className="text-sm text-gray-600">
          {Math.round(scale * 100)}%
        </span>

        <button
          type="button"
          onClick={zoomIn}
          className="px-4 py-2 rounded-md text-sm font-medium bg-gray-100 text-gray-700 hover:bg-gray-200 transition-colors duration-200"
        >
          Zoom In
        </button>

        {/* Existing page navigation controls */}
        {numPages > 1 && (
          <>
            <button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
              className={`
                px-4 py-2 rounded-md text-sm font-medium
                transition-colors duration-200
                ${pageNumber <= 1
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }
              `}
            >
              Previous
            </button>

            <span className="text-sm text-gray-600">
              Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
            </span>

            <button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
              className={`
                px-4 py-2 rounded-md text-sm font-medium
                transition-colors duration-200
                ${pageNumber >= numPages
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }
              `}
            >
              Next
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default PDFDisplay;