const FileDropZone = ({ onFileSelect }) => {
  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file && file.type === 'application/pdf') {
      onFileSelect(file);
    } else {
      alert('Please select a PDF file');
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files?.[0];
    
    if (file && file.type === 'application/pdf') {
      onFileSelect(file);
    } else {
      alert('Please drop a PDF file');
    }
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center hover:border-indigo-500 transition-colors cursor-pointer"
    >
      <input
        type="file"
        id="file-input"
        accept=".pdf"
        onChange={handleFileChange}
        className="hidden"
      />
      
      <label htmlFor="file-input" className="cursor-pointer">
        <div className="space-y-4">
          {/* Upload icon */}
          <svg 
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            />
          </svg>

          <div className="text-gray-600">
            <span className="font-medium">Click to upload</span> or drag and drop
            <p className="text-sm text-gray-500">PDF files only</p>
          </div>
        </div>
      </label>
    </div>
  );
};

export default FileDropZone; 