import React, { useRef, useState, useEffect, useCallback } from 'react';
import { API_BASE_URL } from '../config';

const AudioPlayer = ({ audioId, filename }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playbackSpeed, setPlaybackSpeed] = useState(1.0);
  const audioRef = useRef(null);

  const formatTime = useCallback((timeInSeconds) => {
    if (!timeInSeconds) return '0:00';
    
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }, []);

  const handleTimeUpdate = useCallback(() => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime || 0);
    }
  }, []);

  const handleLoadedMetadata = useCallback(() => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration || 0);
    }
  }, []);

  const handleEnded = useCallback(() => {
    setIsPlaying(false);
    setCurrentTime(0);
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
      audioRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audioRef.current.removeEventListener('ended', handleEnded);
      
      audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
      audioRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
      audioRef.current.addEventListener('ended', handleEnded);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
        audioRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
        audioRef.current.removeEventListener('ended', handleEnded);
        audioRef.current = null;
      }
    };
  }, [audioId, handleTimeUpdate, handleLoadedMetadata, handleEnded]);

  const handlePlay = async () => {
    try {
      if (!audioRef.current) {
        audioRef.current = new Audio(`${API_BASE_URL}/stream/${audioId}`);
        audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
        audioRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
        audioRef.current.addEventListener('ended', handleEnded);
        
        await audioRef.current.play();
        setIsPlaying(true);
      } else if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        await audioRef.current.play();
        setIsPlaying(true);
      }
    } catch (error) {
      console.error('Error playing audio:', error);
    }
  };

  const handleProgressChange = (e) => {
    const newTime = (e.target.value / 100) * duration;
    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const changePlaybackSpeed = (speed) => {
    setPlaybackSpeed(speed);
    if (audioRef.current) {
      audioRef.current.playbackRate = speed; // Update the playback rate of the audio element
    }
  };

  return (
    <div className="space-y-3 p-3">
      <div className="flex items-center justify-between flex-row-reverse">
        <span className="text-gray-700 font-medium">
          {filename.split('_')[0]}
        </span>
        <div className="space-x-3">
          <button 
            onClick={handlePlay}
            className="px-4 py-2 bg-indigo-50 text-indigo-600 rounded-lg hover:bg-indigo-100 transition-all font-medium"
          >
            {isPlaying ? 'Pause' : 'Play'}
          </button>
        </div>
      </div>
      
      <div className="flex items-center space-x-3">
        <label className="text-gray-600 text-sm font-medium">Speed:</label>
        <div className="flex items-center bg-gray-100 rounded-lg p-1">
          <button 
            onClick={() => changePlaybackSpeed(Math.max(0.5, playbackSpeed - 0.25))}
            className="w-7 h-7 flex items-center justify-center rounded-md hover:bg-white hover:shadow-sm transition-all duration-200 text-gray-600 hover:text-gray-900"
            aria-label="Decrease playback speed"
          >
            －
          </button>
          <span className="w-14 text-center font-medium text-gray-700">
            {playbackSpeed.toFixed(2)}x
          </span>
          <button 
            onClick={() => changePlaybackSpeed(Math.min(2.0, playbackSpeed + 0.25))}
            className="w-7 h-7 flex items-center justify-center rounded-md hover:bg-white hover:shadow-sm transition-all duration-200 text-gray-600 hover:text-gray-900"
            aria-label="Increase playback speed"
          >
            ＋
          </button>
        </div>
      </div>

      <div className="space-y-2">
        <input
          type="range"
          min="0"
          max="100"
          value={duration ? (currentTime / duration) * 100 : 0}
          onChange={handleProgressChange}
          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
        />
        <div className="flex justify-between text-sm text-gray-500">
          <span>{formatTime(currentTime)}</span>
          <span>{formatTime(duration)}</span>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer; 