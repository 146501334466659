import React, { useState } from 'react';
import axios from 'axios';
import ManageVoicesModal from './ManageVoicesModal';

export default function AddVoiceModal({ onClose, onVoiceAdded, userVoices, onVoiceRemoved }) {
  const [name, setName] = useState('');
  const [voiceId, setVoiceId] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showManageVoices, setShowManageVoices] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const token = localStorage.getItem('token');
      
      if (!token) {
        setError('Please sign in to add custom voices.');
        window.dispatchEvent(new CustomEvent('auth-error'));
        return;
      }

      const response = await axios.post(
        '/voices/custom',
        {
          name,
          voice_id: voiceId
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data) {
        onVoiceAdded(response.data);
        onClose();
      }
    } catch (error) {
      console.error('Error adding voice:', error);
      
      if (error.response?.status === 422 || error.response?.status === 401) {
        setError('Your session has expired. Please sign in again.');
        window.dispatchEvent(new CustomEvent('auth-error'));
      } else if (error.response?.status === 409) {
        setError('This voice already exists in your collection.');
      } else {
        setError(error.response?.data?.error || 'Failed to add voice. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 w-full max-w-md">
        <div className="relative bg-white rounded-2xl shadow-xl p-8">
          {/* Close Button */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 transition-colors"
            aria-label="Close modal"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          {/* Header */}
          <div className="flex items-center justify-between mb-8">
            <h2 className="text-2xl font-bold text-gray-900">Add Custom Voice</h2>
            <button
              type="button"
              onClick={() => setShowManageVoices(true)}
              className="px-4 py-2 text-sm bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-all duration-200"
            >
              Manage Voices
            </button>
          </div>

          {/* Form */}
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-2">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Voice Name
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-200"
                placeholder="Enter voice name"
                required
              />
            </div>

            <div className="space-y-2">
              <label htmlFor="voiceId" className="block text-sm font-medium text-gray-700">
                Voice ID
              </label>
              <input
                type="text"
                id="voiceId"
                value={voiceId}
                onChange={(e) => setVoiceId(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-200"
                placeholder="Enter voice ID"
                required
              />
            </div>

            {error && (
              <div className="p-3 bg-red-50 text-red-700 rounded-lg text-sm">
                {error}
              </div>
            )}

            <button
              type="submit"
              disabled={isLoading}
              className="w-full px-4 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-all duration-200 disabled:bg-gray-400 disabled:cursor-not-allowed"
            >
              {isLoading ? (
                <div className="flex items-center justify-center space-x-2">
                  <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin" />
                  <span>Adding Voice...</span>
                </div>
              ) : (
                'Add Voice'
              )}
            </button>
          </form>
        </div>
        
        {/* Manage Voices Modal */}
        {showManageVoices && (
          <ManageVoicesModal
            userVoices={userVoices}
            onVoiceRemoved={onVoiceRemoved}
            onClose={() => setShowManageVoices(false)}
          />
        )}
      </div>
    </div>
  );
} 